import React from "react";
import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Hello from "./Hello";
import Report from "./Report";
import Account from "./Account";

function App() {
  const [result, setResult] = useState(null);

  useEffect(() => {
    async function getReport() {
      const res = await fetch("https://imagine.ngrok.io/slack/report");
      const result = await res.json();
      setResult(result);
      console.log("Result:", result);
    }
    // getReport();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Hello} exact />
          <Route path="/hello" component={Hello} exact />
          <Route path="/report" component={Report} />
          <Route path="/account" component={Account} />
          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
