import React from "react";

export default props => {
  return (
    <main>
      <h1>CodeGems coding challenge !</h1>
      <h2>
        Deployed with{" "}
        <a
          href="https://vercel.com/docs"
          target="_blank"
          rel="noreferrer noopener"
        >
          Vercel
        </a>
        !
      </h2>
      <p>
        <a href="https://imagine.ngrok.io/auth/slack/identity">
          Connect by logging in with Slack
        </a>{" "}
      </p>
      <br />
    </main>
  );
};
