import React, { useState, useEffect } from "react";
const queryString = require("query-string");

export default props => {
  const [session, setSession] = useState();

  const getSession = () => {
    console.log("query string:", window.location);
    fetch(
      `https://imagine.ngrok.io/session?slack_user_id=${
        queryString.parse(window.location.search).slack_user_id
      }`
    ).then(r => {
      console.log("Session result");
      r.json().then(res => {
        console.log(res);
        setSession(res);
      });
    });
    console.log(queryString.parse(window.location.search));
  };

  useEffect(() => {
    getSession();
  }, []);

  return (
    <main>
      <h1>Account</h1>

      {session && session.cookie ? Object.keys(session.cookie).join(" : ") : ""}
      {session && session.passport
        ? Object.keys(session.passport).join(" : ")
        : ""}
      {session && session.session
        ? Object.keys(session.session).join(" : ")
        : ""}
      {Object.keys(queryString.parse(window.location))}

      <br />
    </main>
  );
};
