import React, { useState, useEffect } from "react";
import Navbar from "./Navbar.js";

export default props => {
  useEffect(() => {
    console.log("Report");
    fetch("https://imagine.ngrok.io/slack/report").then(console.log);
  }, []);
  return (
    <main>
      <h1>Slack Report</h1>
      <h2>
        Deployed with{" "}
        <a
          href="https://vercel.com/docs"
          target="_blank"
          rel="noreferrer noopener"
        >
          Vercel
        </a>
        !
      </h2>
      <p>
        <a href="https://imagine.ngrok.io/auth/slack">
          Authorize more permissions to get workspace history
        </a>{" "}
      </p>
      <br />
    </main>
  );
};
